import ReactMarkdown from "react-markdown";
import { useRouter } from "next/router";

import { IImage } from "@tm/interfaces";
import { Text } from "../Text";
import { pushEvent } from "@tm/utility/analytics";

import {
  LandingMap,
  LandingMapHd,
  LandingMapLeft,
  LandingMapScroll,
  LandingMapWrap,
  ScrollBall,
} from "./index.style";
import MapCompNew from "../MapCompNew/MapCompNew";
import { useScroll } from "../../hooks/useScroll";

interface IHero {
  title?: string;
  subTitle?: string;
  mapData?: any;
  foreignCurrencyCTALinkEvent: any;
  forexCardsCTALinkEvent: any;
  travelLoanCTALinkEvent: any;
  travelInsuranceCTALinkEvent: any;
}

type Renderers = {
  [type: string]: (props: any) => React.ReactNode;
};

const Hero = ({
  title,
  subTitle,
  mapData,
  foreignCurrencyCTALinkEvent,
  forexCardsCTALinkEvent,
  travelLoanCTALinkEvent,
  travelInsuranceCTALinkEvent,
}: IHero) => {
  const router = useRouter();
  const { scroll } = useScroll();

  const handleLinkClick = (props: any) => {
    if (props?.title === "forex-currency") {
      pushEvent(foreignCurrencyCTALinkEvent);
    } else if (props?.title === "forex-card") {
      pushEvent(forexCardsCTALinkEvent);
    } else if (props?.title === "travel-insurance") {
      pushEvent(travelInsuranceCTALinkEvent);
    } else if (props?.title === "travel-credit-line") {
      pushEvent(travelLoanCTALinkEvent);
    }
    router.push(props?.href);
  };

  const renderers: Renderers = {
    a: (props: any) => (
      <a onClick={() => handleLinkClick(props)}>{props.children}</a>
    ),
  };
  return (
    <LandingMap>
      <LandingMapWrap>
        <LandingMapLeft id="LandingMapLeft">
          {title && (
            <LandingMapHd>
              <Text text={title} />
            </LandingMapHd>
          )}
          {subTitle && (
            <ReactMarkdown components={renderers}>{subTitle}</ReactMarkdown>
          )}
        </LandingMapLeft>
        {mapData && <MapCompNew mapData={mapData} />}
      </LandingMapWrap>
      <LandingMapScroll scroll={scroll}>
        <ScrollBall></ScrollBall>
      </LandingMapScroll>
    </LandingMap>
  );
};

export default Hero;
