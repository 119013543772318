import React from "react";

export const Text = ({ text }: { text: string }): JSX.Element => {
  return (
    <>
      {text.split("\n").map((item, index, text) => {
        return (
          <React.Fragment key={index}>
            {item}
            {index !== text.length - 1 ? <br /> : null}
          </React.Fragment>
        );
      })}
    </>
  );
};
