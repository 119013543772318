import styled from "styled-components";
import {
  BG_COLOR,
  TEXT_COLOR,
  BORDER_COLOR,
  BLUE_COLOR,
  GRADIENT_COLOR,
} from "@tm/utility/theme";

interface ILandingMapScroll {
  scroll: boolean;
}

export const LandingMapParent = styled.div`
  background: ${BG_COLOR.black};
`;

export const LandingMap = styled.div`
  // background: ${BG_COLOR.vividBlack};
  // // padding-top: 75px;
  // padding-left: 120px;
  // padding-right: 120px;
  // // padding-bottom:90px;
  // padding-bottom:200px;
  // padding-top:40px;

  // color: ${TEXT_COLOR.white};
  // font-weight: 400;
  // font-size: 22px;
  // line-height: 24px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  background: #010101;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 125px;
  padding-top: 40px;
  color: #fff;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LandingMapWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 750px 700px;
  height: 600px; */
`;

export const LandingMapScroll = styled.div<ILandingMapScroll>`
  width: 34px;
  height: 60px;
  background: ${BG_COLOR.vividBlack};
  border: 1px solid ${BORDER_COLOR.dimGrey};
  border-radius: 33px;
  padding: 10px;
  position: relative;
  top: -90px;
  opacity: 0;

  ${(props) =>
    props.scroll &&
    `
 opacity:1;
	`}
`;

export const ScrollBall = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: ${BG_COLOR.white};
  animation: bounce 1.5s;
  animation-iteration-count: infinite;

  @keyframes bounce {
    from {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    to {
      transform: translate3d(0, 26px, 0);
      opacity: 0;
    }
  }
`;

export const LandingMapLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 607px;
  z-index: 1;
  margin-top: -55px;
  img {
    margin-right: 0.75rem;
    vertical-align: middle;
  }
  p {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  p em {
    color: ${BLUE_COLOR.lightSkyBlue};
    font-weight: 700;
    text-decoration: none;
    font-style: normal;
  }

  h3 {
    font-weight: 600;
    font-size: 48px;
    line-height: 36px;
    color: ${TEXT_COLOR.aquamarine};
    margin-bottom: 16px;
    margin-top: 2.5rem;
  }

  h4 {
    font-weight: normal;
    font-size: 22px;
  }
`;

export const LandingMapRight = styled.div`
  width: 700px;
  height: 700px;
  background-position: top right;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LandingMapIndia = styled.div`
  width: 326px;
  height: 357px;
  flex-shrink: 0;
`;

export const LandingMapHd = styled.h2`
  background: linear-gradient(
    91.72deg,
    ${GRADIENT_COLOR.lightPink} 0.38%,
    ${GRADIENT_COLOR.snow} 38.24%,
    ${GRADIENT_COLOR.skyBlue} 75.95%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 500;
  font-size: 56px;
  line-height: 72px;
  margin-bottom: 16px;
  font-family: "Raleway";
`;
