import React from "react";
import { theme } from "@tm/utility/global";
import SpanTag from "../TextStyles/SpanTag";
import { RippleCompStyle, InnerDot, RippleCompWrap } from "./MapCompNew.style";
import { convertToPascalCase } from "@tm/utility";

interface RippleProps {
  posX?: string;
  posY?: string;
  colorCode?: string;
  bgColor?: string;
  borderColor?: string;
  pointDetailType?: boolean;
  icon?: any;
  animDelay?: number;
  cityName?: string;
  countryName?: string;
}

const RippleComp: React.FC<RippleProps> = ({
  posX,
  posY,
  pointDetailType,
  bgColor,
  borderColor,
  icon,
  animDelay,
  cityName,
  countryName,
}) => {
  return (
    <RippleCompWrap posX={posX} posY={posY} key={+new Date()}>
      <RippleCompStyle
        animDelay={animDelay}
        pointDetailType={pointDetailType}
        bgColor={bgColor}
        borderColor={borderColor}
      ></RippleCompStyle>

      <InnerDot
        animDelay={animDelay}
        borderColor={borderColor}
        pointDetailType={pointDetailType}
      >
        {icon}
        {pointDetailType && (
          <SpanTag color={theme.whiteText} fontSize="xs">
            Card swipe in
            <br />
            <SpanTag
              color={theme.whiteText}
              fontSize="xs"
              fontWeight="semiBold"
            >
              {convertToPascalCase(cityName)},{" "}
              {convertToPascalCase(countryName)}
            </SpanTag>
          </SpanTag>
        )}
      </InnerDot>
    </RippleCompWrap>
  );
};

export default RippleComp;
