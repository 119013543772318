import React from "react";
import { theme } from "@tm/utility/global";

import FlexComp from "../FlexComp/FlexComp";
import H6Tag, { H6Color } from "../TextStyles/H6Tag";
import SpanTag from "../TextStyles/SpanTag";
import { IndiaWrap, MapIcon } from "./MapCompNew.style";
import RippleComp from "./RippleComp";

import { IImage } from "@tm/interfaces";
import { useMapData } from "../MapComp/hooks/useMapData";
import { cities } from "@tm/utility/constants";
import useInfiniteMapEvents from "../../hooks/useInfiniteMapEvents";
import { Legend } from "./styled";
import { Image } from "../Image";

interface MapProps {
  mapPoints?: object[];
  mapData?: any;
}

function randomIntFromInterval(min: number, max: number) {
  var delay;
  delay = Math.floor(Math.random() * (max - min + 1) + min);
  return delay;
}

var midX = 350;
var midY = 275;

const MapCompNew: React.FC<MapProps> = ({ mapData }) => {
  const [array] = useMapData();
  const { displayedArray } = useInfiniteMapEvents(array);
  const pointType = mapData?.pointType;

  return (
    <IndiaWrap className="indiaMapWrap">
      <FlexComp margin="0 0 35px">
        <img {...mapData?.mapImg} alt="map" />
        {displayedArray &&
          displayedArray.map((item: any, index: number) => {
            const cityName = item.cityName;
            var x;
            var x1;
            var newx;
            var x2;
            var y;
            var y1;
            var newy;
            var y2;
            x2 = cities[cityName.toLowerCase()][0] / 1.579 - 100;
            y2 = cities[cityName.toLowerCase()][1] / 1.579 - 251;
            x = `${cities[cityName.toLowerCase()][0] / 1.579 - 100}px`;
            y = `${cities[cityName.toLowerCase()][1] / 1.579 - 251}px`;
            newx = `${(x2 + midX) / 2}px`;
            newy = `${(y2 + midY) / 2}px`;
            x1 =
              JSON.stringify(item.event_name) == JSON.stringify(pointType[0])
                ? newx
                : x;
            y1 =
              JSON.stringify(item.event_name) == JSON.stringify(pointType[0])
                ? newy
                : y;
            return (
              <RippleComp
                key={index}
                cityName={item.cityName}
                countryName={item.countryName}
                posX={x1}
                posY={y1}
                bgColor={item.event_name.bgColorProp}
                borderColor={item.event_name.borderColorProp}
                icon={item.event_name.icon}
                pointDetailType={
                  JSON.stringify(item.event_name) ==
                  JSON.stringify(pointType[0])
                }
                animDelay={randomIntFromInterval(1, 10)}
              />
            );
          })}
      </FlexComp>
      <Legend>
        <H6Tag color={theme.whiteText as H6Color} margin="0 0 12px">
          {mapData?.mapTitle}
        </H6Tag>
        <FlexComp gap="25px" alignItems="center">
          {pointType &&
            pointType.map((item: any) => (
              <FlexComp gap="6px" alignItems="center" key={item.type}>
                <MapIcon>
                  <Image
                    src={item.icon}
                    height="24"
                    width="24"
                    loading="eager"
                  />
                </MapIcon>
                <SpanTag color={theme.greyText8} fontSize="xs">
                  {item.type}
                </SpanTag>
              </FlexComp>
            ))}
        </FlexComp>
      </Legend>
    </IndiaWrap>
  );
};

export default MapCompNew;
