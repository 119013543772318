import styled, { keyframes } from "styled-components";
import { SpanTagStyle } from "../TextStyles/TextStyles.style";

const ripple = keyframes`
  0% {
    width: 0;
    height: 0;
    border-width:0;
    opacity: 1;
  }
  100% {
   width:434px;
   height:434px;
   opacity: 0;
   border-width:1px;
  }
`;

const ripple2 = keyframes`
  0% {
    width: 0;
    height: 0;
    border-width:0;
    opacity: 1;
  }
  100% {
   width:64px;
   height:64px;
   opacity:0;
   border-width:1px;
  }
`;

const rippleIcon = keyframes`
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  100% {
   opacity:0;
  }
`;

export const IndiaWrap = styled.div`
  width: 700px;
  height: 550px;
  flex-shrink: 0;
  position: relative;
  top: 51px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: -90px;
  }
`;

export const RippleCompWrap = styled.div<any>`
  position: absolute;
  top: ${(p) => (p.posY ? p.posY : "auto")};
  left: ${(p) => (p.posX ? p.posX : "auto")};
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(p) => (p.pointDetailType ? "434px" : "64px")};
  height: ${(p) => (p.pointDetailType ? "434px" : "64px")};
`;

export const RippleCompStyle = styled.div<any>`
  width: 0;
  height: 0;
  opacity: 0;
  background: ${(p) => (p.bgColor ? p.bgColor : "")};
  border: 1px solid ${(p) => (p.borderColor ? p.borderColor : "")};
  border-radius: 100%;
  animation: ${(p) => (p.pointDetailType ? ripple : ripple2)} 4s ease-in-out;
  flex-shrink: 0;
  animation-delay: ${(p) => (p.animDelay ? p.animDelay : "")}s;
`;

export const InnerDot = styled.div<any>`
  width: 4px;
  height: 4px;
  opacity: 0;
  border-radius: 100%;
  background: ${(p) => (p.borderColor ? p.borderColor : "")};
  position: absolute;
  animation: ${rippleIcon} ease-in-out;
  animation-duration: ${(p) => (p.pointDetailType ? 5 : 4)}s;
  animation-delay: ${(p) => (p.animDelay ? p.animDelay + 0.7 : "")}s;
  text-align: center;
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  gap: 10px;
  svg {
    position: absolute;
    top: -23px;
    left: -10px;
  }
  & > ${SpanTagStyle} {
    min-width: 90px;
    margin-top: 50px;
  }
`;

export const MapIcon = styled.div`
  margin-top: 10px;
`;
